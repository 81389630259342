:root {
  --primary-text-color: #444444;
  --secondary-text-color: #555555; /* with alpha: 44444488 */
  --deemphasized-text-color: var(--secondary-text-color); /* e.g.: N/A for derived values */
  --icer-green: #009d77;
  --icer-blue: #0071ce; /* theme.palette.secondary.A400 */
  --dark-red: #632121;
  --beige-sand: #eadeb3;
  --red: #d64545;
  --zebra-dark: #f7f9fA;
  --zebra-light: #ffffff;
  --subrow-hover-color: #e5f5f1;
  --subrow-focused-color: #f8fbd0;
  --mui-grid-factor: 8px;
  --main-header-height: 79px;
  --tab-bar-height: 85px;
  --utility-bar-height: 40px;
  --table-header-height: calc(var(--main-header-height) + var(--tab-bar-height));
  --table-gutter-width: calc(5 * var(--mui-grid-factor));
  --table-first-cell-padding: 32px;
  --table-max-width: 1440px;
  --menu-width: 40px;
  --details-header-height: 108px;
  --details-breadcrumb-height: 58px;
  --footer-height: 32px;
  --first-column-width: calc(57px + var(--table-first-cell-padding));
  --offset-from-column-width: 0px;
  --second-column-offset-left: calc(var(--first-column-width) + var(--offset-from-column-width));
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1em;
  color: var(--primary-text-color);
  /* suppress scrolling on body -- but then, scroll container will have to be defined for details and devise views: */
  overflow: hidden;
}

/* --------------- Devise ------------------ */
body.devise_view {
  & div#devise_body {
    position: absolute;
    top: var(--main-header-height);

    overflow: auto;
    width: 100vw;
    max-height: calc(100vh - var(--main-header-height) - var(--footer-height));

    padding-left: var(--table-gutter-width);
    padding-right: var(--table-gutter-width);

    & div {
      margin-bottom: 8px;
    }
  }
}
/* ----------------------------------------- */

body.active_admin {
  /*
    Currently, this file is not loaded into ActiveAdmin views.
    Instead, edit the following:
      app/assets/stylesheets/active_admin.scss
  */
}

a {
  color: var(--icer-blue);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

footer#footer {
  height: var(--footer-height);
  max-height: var(--footer-height);
  padding-left: var(--table-gutter-width);
  padding-right: var(--table-gutter-width);
  padding-top: var(--mui-grid-factor);
  padding-bottom: var(--mui-grid-factor);
  color: var(--secondary-text-color);
}

div.analyses-container {
  /* gutter is only on the right, because menu is flush-left: */
  /* max-width: calc(100vw - 20px); */
  min-width: 100%;
  /* Note: the ^above needs to accommodate maximum width of vertical scrollbar */
  border-bottom: 1px solid rgb(224, 224, 224);
}

main section:only-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

#main-header {
  height: var(--main-header-height);
  max-height: var(--main-header-height);
}

#tab-bar {
  min-height: var(--tab-bar-height);
}

#details-header {
  white-space: nowrap;
  min-height: auto;
}

#details-breadcrumb {
  white-space: nowrap;
}

#details-body {
  overflow: auto;
  max-width: 100vw;
  display: flex;
  /**
   *   Nested; does not include:
   *         var(--main-header-height)
   */
  /* --details-top: calc(var(--details-header-height) + var(--details-breadcrumb-height));

  top: var(--details-top);

  max-height: calc(100vh - var(--main-header-height) - var(--details-top) - var(--footer-height)); */
}

.body-flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.body-flex #details-header {
  flex: 0 1 auto;
  white-space: normal;
  padding-right: 32px;
}

.body-flex #spa_body {
  flex: 1 1 100%;
  position: static;
  padding-top: var(--main-header-height);
  padding-bottom: var(--footer-height);
  max-height: calc(100vh - var(--main-header-height) - var(--footer-height))
}

.body-flex #details-body {
  flex: 1 1 auto;
}

div#spa_body {
  position: absolute;
  width: 100vw;
  top: var(--main-header-height);
  height: calc(100vh - var(--main-header-height) - var(--footer-height));
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

#utility_bar {
  height: var(--utility-bar-height);
}

.population_and_comparator div,
.annual_dosage_assumptions div {
  max-width: 150px;
}

table th a, table td a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

form.analyses {
  /* Note: the ^above needs to accommodate maximum width of vertical scrollbar */

  max-width: 100vw;
  flex: 0 1 auto;
  max-height: calc(100vh - var(--table-header-height) - var(--footer-height) - var(--utility-bar-height) - 14px);

  /* scrolling defined in container surrounding table -- not *in* the table, which has sticky headers: */
  overflow: auto;
  position: relative;

  .remove_from_compare {
    padding-left: var(--table-first-cell-padding);
  }

  & table {
    position: relative;
    /* user-select: none; */
    border-collapse: separate;
  }
  & thead {
    & th {
      z-index: 95;
      white-space: pre-wrap;
      border-right: 1px dotted #ccc;
      top: 0;
      & div {
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
    & tr:nth-of-type(1) {
      top: 0;
      height: 42px;
      & th {
        position: sticky;
        top: 0;
      }
      & th[rowspan="2"] { /* 1st thead row, all cells that span 2 rows */
        z-index: 94;
        /* offset-x | offset-y | blur-radius | spread-radius | color */
        /*box-shadow: 0px 10px 10px 0px rgba(127, 127, 127, 0.15);*/
      }
      & th:nth-of-type(1) { /* 1st thead row, 1st cell: add_to_compare */
        z-index: 101; /* top-left corner, add_to_compare, on top of everything */
        left: 0;
        width: var(--first-column-width);
        box-shadow: 0px 10px 10px 0px rgba(127, 127, 127, 0.15);
      }
      & th:nth-of-type(2) { /* 1st thead row, 2nd cell: trade_name */
        z-index: 99;
        left: var(--second-column-offset-left);
        /* shadow on both bottom and right: */
        box-shadow: 0px 10px 10px 0px rgba(127, 127, 127, 0.15);
      }
    }
    & tr:nth-of-type(2) { /* 2nd thead row of top headers */
      & th {
        z-index: 93; /* on top of tbody 2nd cell, trade_name */
        position: sticky;
        top: 42px;
        /*box-shadow: 0px 10px 10px 0px rgba(127, 127, 127, 0.15);*/
      }
    }
    & th:last-of-type {
      padding-right: var(--table-first-cell-padding);
    }
  }
  & tbody {
    /*
      icer_vbpb_price_row
      user_proposed_price_row
      net_price_row
      wac_price_row
    */

    & td:last-of-type {
      padding-right: var(--table-first-cell-padding);
    }

    & tr.manufacturer_price_row {
      & td:not(:first-of-type) { /* only the vbpb comparison values */
        font-style: italic;
      }
    }

    & tr.icer_vbpb_price_row {
      & td.usd { /* only the vbpb comparison values */
        font-weight: bold;
        & small {
          font-weight: normal;
        }
      }
    }

    & tr.user_proposed_price_row.has-icer-price {
      &:hover {
        /* user-select: none; */
        cursor: pointer;
        background-color: var(--subrow-hover-color);
      }
      &.focused {
        background-color: var(--subrow-focused-color);
      }
    }

    & tr:not(:last-child):not(:first-child) {
      & td {
        border-bottom:  1px dashed #ccc;
      }
    }
    & tr:first-child:not(:last-child) {
      /* 1st subrow td elements: */
      & td[rowspan="1"] {
        border-bottom: 1px dashed #ccc;
      }
    }
    & td {
      vertical-align: top;
      border-right: 1px dotted #ccc;
      & small {
        color: var(--deemphasized-text-color);
      }
    }
    & td.icer_vbpb_price_label,
    & td.user_proposed_price_label,
    & td.manufacturer_price_label,
    & td.net_price_label,
    & td.wac_price_label
    {
      text-align: right;
      font-weight: bold;
      white-space: nowrap;
    }
    & th:nth-of-type(1) { /* tbody rows, 1st cell: add_to_compare */
      z-index: 94;
      position: sticky;
      left: 0;
      vertical-align: top;
      border-right: 1px dotted #ccc;
      width: var(--first-column-width);
      vertical-align: middle;
      text-align: center;
    }
    & th:nth-of-type(2) { /* tbody rows, 2nd cell: trade_name */
      z-index: 92;
      position: sticky;
      left: var(--second-column-offset-left);
      vertical-align: top;
      border-right: 1px dotted #ccc;
      box-shadow: 10px 0px 10px 0px rgba(127, 127, 127, 0.15);
    }
  }
  & tbody:nth-of-type(odd) {
    background: var(--zebra-dark);
  }
  & tbody:nth-of-type(even) {
    background: var(--zebra-light);
  }
  & tbody:nth-of-type(odd) th {
    background: var(--zebra-dark);
  }
  & tbody:nth-of-type(even) th {
    background: var(--zebra-light);
  }
  & tbody.unpublished {
    background-color: #fdd;
    & th {
      background-color: #fdd;
    }
  }
  & tbody.status-row {
    & tr {
      & td {
        background-color: white;
        & h2, & div {
          position: sticky;
          left: 0;
          display: inline-block; /* otherwise, width of element is 100% */
        }
      }
    }
  }
}

/*
  background-image url is specified here, b/c webpack build process:
    1.) generates a filename that includes a checksum of the file contents (for more seamless updates and re-deploys)
    1.) include the referenced files as an asset served publicly (rake assets:precompile)
    2.) gets correct path from the CSS build output to ^that file
*/
.supportsTooltip.scrollable {
  background-image: url('./icons/info-24px.svg');
}

.landing {
  padding-bottom: 100px;
  max-width: 100vw;
  overflow-x: hidden;
  & div.landing-scrollable-content {
    max-width: calc(100vw - (2 * var(--table-gutter-width)));
    max-height: calc(100vh - var(--main-header-height) - var(--footer-height));
    overflow: auto;
    position: relative;
    padding-left: var(--table-gutter-width);
    padding-right: var(--table-gutter-width);
    /* padding-bottom: calc(40px + var(--footer-height)); */
  }

  & .thumbnail {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: top left;
    /* border: 1px red dashed; */
  }

  & section#ec {
    padding-top: 32px;
    & .thumbnail.ec-preview {
      background-image: url('./landing/ec-preview765x495.png');
      /* ec-preview1020x660.png */
      background-size: 765px 495px;
      width: 765px;
      height: 495px;
    }
    & .thumbnail.no-more-hunting {
      background-image: url('./landing/ec-no-more-hunting.svg');
      width: 120px;
      height: 120px;
    }
    & .thumbnail.compare-pricing {
      background-image: url('./landing/ec-compare-pricing.svg');
      width: 120px;
      height: 120px;
    }
    & .thumbnail.catch-meeting-highlights {
      background-image: url('./landing/ec-catch-meeting-highlights.svg');
      width: 121px; /* this one is one pixel wider? */
      height: 120px;
    }
  }

  & section#ec-plus-im {
    padding: 32px 0 32px 0;
    text-align: center;
    & .thumbnail.ec-plus-im {
      background-image: url('./landing/ec-plus-im-995x500.png');
      background-size: 995px 500px;
      width: 995px;
      height: 500px;
    }
  }

  & section#im {
    padding-top: 32px;
    & .thumbnail.im-preview {
      background-image: url('./landing/im-preview765x495.png');
      /* im-preview1020x660.png */
      background-size: 765px 495px;
      width: 765px;
      height: 495px;
    }
    & .thumbnail.direct-from-icer {
      background-image: url('./landing/im-direct-from-icer.svg');
      width: 120px;
      height: 120px;
    }
    & .thumbnail.connect-to-ec {
      background-image: url('./landing/im-connect-to-ec.svg');
      width: 120px;
      height: 120px;
    }
    & .thumbnail.stay-in-the-know {
      background-image: url('./landing/im-stay-in-the-know.svg');
      width: 120px;
      height: 120px;
    }
  }
}

div.MuiTooltip-popper > div.MuiTooltip-tooltip { /* TODO: use styled-component */
  font-size: 14px;
}

/* Survey Monkey  */
.smcx-embed,
.smcx-iframe-container {
  /* Use important flag to overide inline or injected styles */
  max-width: none !important;
  border: 0 !important;
}
